import { css } from '@emotion/core';

export default css({
    width: '100%',
    padding: '50px 0px 40px',
    display: 'flex',
    justifyContent: 'center',
    background: '#f5333f',
    '& h4': {
        fontSize: 16,
        color: '#d0d2cf',
        fontWeight: 'bold',
        marginBottom: 50,
        textTransform: 'uppercase'
    },
    '& p': {
        fontSize: 16,
        color: '#d0d2cf',
        marginBottom: 5,
        textTransform: 'uppercase'
    },
    '& .privacity': {
        fontSize: 16,
        color: '#d0d2cf',
        fontWeight: 'bold',
        margin: '50px 0px',
        textTransform: 'uppercase',
        textDecoration: 'none',
        display: 'block'
    },
    '& #disclaimer': {
        fontSize: 14,
        textTransform: 'initial'
    },
    '& .footer-links': {
        display: 'none',
        marginRight: 40,
        '& > h4': {
            marginBottom: 30
        },
        '& .host-link': {
            height: 80,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            borderBottom: '1px solid rgba(255,255,255,0.2)',
            '&:last-of-type': {
                borderBottom: 'none',
            }
        },
        '& .host-image': {
            width: 100,
            marginRight: 20
        },
    },
    '& .footer-contact': {
        padding: '0px 40px',
    },
    '@media only screen and (min-width: 1024px)': {
        '& .footer-links': {
            display: 'block',
        },
        '& .footer-contact': {
            borderLeft: '1px solid rgba(255,255,255,0.2)'
        },
    }
});
