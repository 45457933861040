import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

import styles from './styles';

const MainFooter = () => {
    const { cce, cee, cesp } = useStaticQuery(graphql`
        query FooterQuery {
            cce: file(relativePath: { eq: "cce_original.png" }) {
                childImageSharp {
                    fluid(
                        quality: 80,
                        maxWidth: 100
                    ) {
                        ...GatsbyImageSharpFluid,
                        originalName
                    }
                }
            }
            cesp: file(relativePath: { eq: "cespedes.png" }) {
                childImageSharp {
                    fluid(
                        quality: 80,
                        maxWidth: 100
                    ) {
                    ...GatsbyImageSharpFluid,
                    originalName
                    }
                }
            }
            cee: file(relativePath: { eq: "cee-logo.png" }) {
                childImageSharp {
                    fluid(
                        quality: 80,
                        maxWidth: 100,
                        duotone: {
                            highlight: "#FFFFFF",
                            shadow: "#ffffff"
                        }
                    ) {
                    ...GatsbyImageSharpFluid,
                    originalName
                    }
                }
            }
        }
    `);
    return (
        <footer css={styles}>
            <div className="footer-links">
                <h4>Enlaces de interés</h4>
                <a
                    className="host-link"
                    href="https://www.cce.org.mx/"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    <Img
                        className="host-image"
                        fluid={cce.childImageSharp.fluid}
                        alt={cce.childImageSharp.fluid.originalName}
                    />
                    <p>CCE</p>
                </a>
                <a
                    className="host-link"
                    href="http://www.cespedes.org.mx/"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    <Img
                        className="host-image"
                        fluid={cesp.childImageSharp.fluid}
                        alt={cesp.childImageSharp.fluid.originalName}
                    />
                    <p>CESPEDES</p>
                </a>
                <a
                    className="host-link"
                    href="https://cee.colmex.mx/"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    <Img
                        className="host-image"
                        fluid={cee.childImageSharp.fluid}
                        alt={cee.childImageSharp.fluid.originalName}
                    />
                    <p>CEE</p>
                </a>
            </div>
            <div className="footer-contact">
                <h4>Información de contacto</h4>
                <p>Comisión de Energía del Consejo Coordinador Empresarial</p>
                <p>Sócrates 124, Col. Polanco,</p>
                <p>CP. 11540, Ciudad de México</p>
                <p>Teléfono: 55-52-29-11-00</p>
                <Link
                    to="/privacidad"
                    rel="nofollow noopener noreferrer"
                    className="privacity"
                >
                    Aviso de Privacidad
                </Link>
                <p id="disclaimer">Todos los derechos reservados. Consejo Coordinador Empresarial ©2020</p>
            </div>
        </footer>
    );
};

export default MainFooter;
