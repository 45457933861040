import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/common/Header';
import Footer from '../components/home/MainFooter';
import SubHeader from '../components/common/SubHeader';

import MembersCmp from '../components/members';

const Members = () => (
    <>
        <Helmet>
            <title>Comisión de Energía del CCE | Miembros</title>
            <meta name="description" content="Todas las asociaciones, empresas y organismos que conforman a la Comisión de Energía del CCE" />
        </Helmet>
        <main>
            <Header section="members" />
            <SubHeader title="Miembros de la Comisión de Energía" />
            <MembersCmp />
            <Footer />
        </main>
    </>
);

export default Members;
