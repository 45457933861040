import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import styles from './styles';

const MembersCmp = () => {
    const { assocs, companies, organisms } = useStaticQuery(graphql`
        query MembersQuery {
            assocs: allFile(
                filter: {
                    extension: { regex: "/(jpeg|jpg|gif|png)/" },
                    relativeDirectory: { eq: "members/associations"},
                },
                sort: {
                    fields: childImageSharp___sizes___originalName, order: ASC
                }){
                    edges {
                        node {
                            childImageSharp {
                                fluid(
                                    quality: 80,
                                    maxWidth: 260,
                                    maxHeight: 142,
                                ) {
                                    ...GatsbyImageSharpFluid
                                    originalName
                                }
                                id
                            }
                        }
                    }
                }
            companies: allFile(
                filter: {
                    extension: {regex: "/(jpeg|jpg|gif|png)/"},
                    relativeDirectory: {eq: "members/companies"},
                },
                sort: {
                    fields: childImageSharp___sizes___originalName, order: ASC
                }){
                edges {
                    node {
                        childImageSharp {
                            fluid(
                                quality: 80,
                                maxWidth: 260,
                                maxHeight: 142,
                            ) {
                                ...GatsbyImageSharpFluid
                                originalName
                            }
                            id
                        }
                    }
                }
            }
            organisms: allFile(
                filter: {
                    extension: {regex: "/(jpeg|jpg|gif|png)/"},
                    relativeDirectory: {eq: "members/organisms"}
                },
                sort: {
                    fields: childImageSharp___sizes___originalName, order: ASC
                }){
                edges {
                    node {
                        childImageSharp {
                            fluid(
                                quality: 80,
                                maxWidth: 260,
                                maxHeight: 142,
                            ) {
                                ...GatsbyImageSharpFluid
                                originalName
                            }
                            id
                        }
                    }
                }
            }
        }
    `);
    return (
        <section css={styles} className="members">
            <div className="members-box">
                <div className="members-box-section">
                    <h4>Organismos empresariales</h4>
                    <div>
                        {organisms.edges.map((edge) => (
                            <div key={edge.node.childImageSharp.id}>
                                <Img
                                    fluid={edge.node.childImageSharp.fluid}
                                    alt={edge.node.childImageSharp.fluid.originalName}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="members-box-section">
                    <h4>Asociaciones</h4>
                    <div>
                        {assocs.edges.map((edge) => (
                            <div key={edge.node.childImageSharp.id}>
                                <Img
                                    fluid={edge.node.childImageSharp.fluid}
                                    alt={edge.node.childImageSharp.fluid.originalName}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="members-box-section">
                    <h4>Empresas</h4>
                    <div>
                        {companies.edges.map((edge) => (
                            <div key={edge.node.childImageSharp.id}>
                                <Img
                                    fluid={edge.node.childImageSharp.fluid}
                                    alt={edge.node.childImageSharp.fluid.originalName}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MembersCmp;
