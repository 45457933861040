import { css } from '@emotion/core';

export default css({
    width: '100%',
    background: '#f4f7f8',
    padding: '50px 0px',
    '& .members': {
        '&-box': {
            maxWidth: 900,
            margin: '0px auto',
            background: '#fff',
            boxShadow: '0 1px 1px rgba(0,0,0,0.12),0 0px 1px rgba(0,0,0,0.24)',
            '&-section': {
                padding: '0px 0px 20px',
                '& > h4': {
                    fontSize: 22,
                    color: '#2f555e',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    padding: '25px 0px',
                    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                },
                '& > div': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& > div': {
                        width: 260,
                        height: 150,
                        margin: '20px',
                        display: 'block'
                    }
                },
                '&:first-of-type': {
                    '& > h4': {
                        borderTop: 0
                    }
                }
            }
        }
    }
});
